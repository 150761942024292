<template>
  <div class="case-detail-wrap">
    <div class="case-detail-content">
      <div class="head">{{ form.bizNo || "-" }}</div>
      <div class="block case-info">
        <div class="block-title">案件信息</div>
        <div v-for="(item, index) in caseInfo" :key="index" class="kv-info-wrp">
          <div class="kv">
            <div class="k">{{ item.label }}：</div>
            <div class="v">{{ item.value ?? "-" }}</div>
          </div>
        </div>
      </div>
      <div class="block col-rcds">
        <div
          class="flex items-center"
          @click="
            $router.push(
              `/urgeChargeRecord?bizNo=${form.bizNo ?? ''}&type=${
                form.type ?? ''
              }&recordNo=${form.recordNo ?? ''}`
            )
          "
        >
          <div class="block-title">催收记录</div>
          <van-icon class="mr-8" name="arrow" size="0.5rem" />
        </div>
        <div
          v-for="(item, index) in urgeChargeInfo"
          :key="index"
          class="kv-info-wrp"
        >
          <div class="kv">
            <div class="k">{{ item.label }}：</div>
            <div class="v">{{ item.value ?? "-" }}</div>
          </div>
        </div>
      </div>

      <!-- 留白 -->
      <div class="h-56"></div>
      <div class="h-56"></div>

      <!-- 新增催记 -->
      <div class="fixed left-0 bottom-0 w-100vw bg-white py-8 px-16">
        <van-button size="large" color="#1890FF" @click="add()"
          >新增催记
        </van-button>
      </div>

      <!--  -->
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { columnAmount } from "@/utils/tool";

import { collectionFindPagePost } from "@/api";

export default {
  data() {
    return {
      form: {},

      urgeChargeList: [],

      //
    };
  },
  computed: {
    ...mapState("enums", ["relationshipList"]),

    // 案件信息
    caseInfo() {
      return [
        /* {
          label: "订单编号",
          value: this.form.bizNo
        }, */
        {
          label: "客户姓名",
          value: this.form.customerName,
        },
        {
          label: "资方",
          value: this.form.capitalName,
        },
        {
          label: "产品名称",
          value: this.form.productName,
        },
        {
          label: "逾期天数",
          value: columnAmount(this.form.overdueDays, { fixed: 0 }),
        },
        {
          label: "融资金额",
          value: columnAmount(this.form.amount),
        },
        {
          label: "期款",
          value: columnAmount(this.form.periodAmount),
        },
        {
          label: "拖欠金额",
          value: columnAmount(this.form.arrearsAmount),
        },

        //
      ];
    },

    // 催收信息
    urgeChargeInfo() {
      const o = this.urgeChargeList[0] || {};
      const relationshipName = this.relationshipList.find(
        (it) => it.value == o.relationship
      )?.displayName;
      return [
        {
          label: "联系人",
          value: `${relationshipName || ""}-${o.name || ""}-${o.mobile || ""}`,
        },
        {
          label: "催收结果",
          value: o.collectionResult,
        },
        {
          label: "是否结清",
          value: o.settleStatus,
        },
        {
          label: "是否继续催收",
          value: o.continueCollectStatus,
        },
        {
          label: "跟进措施",
          value: o.measures,
        },
        {
          label: "催收日期",
          value: o.collectionDate,
        },
      ];
    },

    //
  },
  created() {
    const form = JSON.parse(sessionStorage.caseDetailsItem || "{}");
    this.form = { ...form };

    this.getUrgeChargeList();

    //
  },
  methods: {
    // 新增
    add() {
      sessionStorage.newReminderItem = JSON.stringify({
        amount: this.form.amount,
        bizNo: this.form.bizNo,
        customerName: this.form.customerName,
        rentBalance: this.form.rentBalance,
        collectionSource: this.form.type,
        recordNo: this.form.recordNo,
      });
      this.$router.push(`/newReminder`);
    },

    // 获取 催收记录
    async getUrgeChargeList() {
      try {
        this.$toast.loading({ duration: 0 });

        const data = await collectionFindPagePost({
          pageNo: 1,
          pageSize: 1,
          bizNo: this.form.bizNo,
          collectionSourceList: [this.form.type],
          recordNo: this.form.recordNo,
          flag: false,
        });
        this.urgeChargeList = data.list || [];

        //
      } catch (err) {
        if (err == "cancel") return;
        setImmediate(() => this.$toast(err));
      } finally {
        this.$toast.clear();
      }

      //
    },

    //
  },
};
</script>
<style lang="scss" scoped>

.case-detail-wrap {
  // width: 100%;
  background: #f5f7f9;
  // height: 100vh;
  position: relative;
  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 284px;
    z-index: 0;
    left: 0;
    top: 0;
    background: linear-gradient(to bottom, #1890ff, #f5f7f9);
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 400px;
    z-index: 1;
    left: 0;
    top: 0;
    background-image: url("https://songcw-sit.oss-cn-shanghai.aliyuncs.com/20230628/Z3ZkzlANGic4DZffUMVWw1TFfBMNybv5.png");
    background-position: 0 0;
    background-size: 750px 400px;
  }
  .case-detail-content {
    position: relative;
    z-index: 10;
    /* height: 100vh;
    width: 100%; */
    .head {
      padding: 48px 24px;
      font-size: 40px;
      font-weight: 600;
      // height: 40px;
      color: #ffffff;
      line-height: 40px;
    }
    .block {
      width: 702px;
      margin: 0 auto;
      background: #ffffff;
      border-radius: 8px;
      overflow: hidden;
      .kv-info-wrp {
        margin: 0 24px;
        border-top: 1px solid #f2f5f5;
        .kv {
          line-height: 76px;
          display: flex;
          font-size: 28px;
          .k {
            color: #666666;
            white-space: nowrap;
          }
          .v {
            color: #333333;
            word-break: break-all;
          }
        }
      }
    }
    .col-rcds {
      margin-top: 24px;
    }
  }
}

</style>
